import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our services | Firecamo Auto Detailing
			</title>
			<meta name={"description"} content={"In Firecamp Auto Detailing, we offer a comprehensive service package designed to improve, protect and rejuvenate your vehicle."} />
			<meta property={"og:title"} content={"Our services | Firecamo Auto Detailing"} />
			<meta property={"og:description"} content={"In Firecamp Auto Detailing, we offer a comprehensive service package designed to improve, protect and rejuvenate your vehicle."} />
			<meta property={"og:image"} content={"https://firecamofest.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://firecamofest.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://firecamofest.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://firecamofest.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://firecamofest.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://firecamofest.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://firecamofest.com/img/images.png"} />
			<meta name={"msapplication-TileColor"} content={"https://firecamofest.com/img/images.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Our services
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
			At ShineMaster Auto Detailing, we offer a comprehensive suite of services designed to enhance, protect, and rejuvenate your vehicle. Discover the range of treatments we provide, each crafted to give your car the care it deserves.

			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://firecamofest.com/img/6.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://firecamofest.com/img/7.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://firecamofest.com/img/8.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-10">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
			/>
			<Box min-width="100px" min-height="100px" margin="0px 0px 16px 0px">
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Detailed Service Offerings
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px 54px"
					margin="0px 0px 25px 0px"
					md-grid-template-columns="1fr"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
						Exterior Detailing:
							<br />
							Deep Cleaning – Thorough washing and drying to remove dirt and grime.
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
						Paint Protection – Application of high-quality waxes and sealants to protect and enhance your car's paintwork.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
						Interior Detailing:
							<br />
							Conditioning – Treatment of leather and upholstery to rejuvenate and protect.
						</Text>
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
						
						Comprehensive Cleaning – Detailed cleaning of seats, carpets, and all interior surfaces.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});